// --------------------------------------------------------------------------
// exampleModule.js
// --------------------------------------------------------------------------
// Brief explanation of what the module is used for.
// --------------------------------------------------------------------------

RN.scenes = function() {

  'use strict';

  function init() {

    byElement('scene-exit').each(function() {
        var inview = new Waypoint.Inview({
            element: $(this)[0],
            exit: function(direction) {
                $(this.element).addClass('in-view');
            }
        });
    });


    byElement('scene-exited').each(function() {
        var inview = new Waypoint.Inview({
            element: $(this)[0],
            exited: function() {
                var el = $(this.element).next();
                el.addClass('in-view');
                if(el.hasClass('scene--vantage')) {
                    setTimeout(function() {
                        $('.curve').addClass('in-view');
                    }, 3000);
                }
                if(el.hasClass('scene--wealth')) {
                    setTimeout(function() {
                        $('.wealth').addClass('in-view');
                    }, 3000);
                }
            }
        });
    });

    byElement('scene-entered').each(function() {
        var inview = new Waypoint.Inview({
            element: $(this)[0],
            entered: function() {
                $(this.element).addClass('in-view');
                if($(this.element).parent().hasClass('scene--vantage')) {
                    setTimeout(function() {
                        $('.curve').addClass('in-view');
                    }, 3000);
                }
            }
        });
    });
  }

  return {
    init: init
  };

}();